import React, { Component} from 'react';
import { Card, CardBody, CardText  } from "reactstrap";

export default class Footer extends Component {
    render() {
        return (
            <div className="footerContent">
                <Card className="footerCard square">
                    <CardBody className="footerText">
                        <CardText>Juan's El Caporal Mexican Restaurant</CardText>
                        <CardText>14420 Hwy 62 Eagle Point, OR 97524</CardText>
                        <CardText>(541) 826-4360</CardText>
                    </CardBody>
                </Card>
            </div>
        )
    }
}