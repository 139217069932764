import React from 'react';
import { UncontrolledCarousel } from 'reactstrap';
import { Card, CardHeader, CardTitle } from 'reactstrap';
import slide1 from '../images/bar.jpg';
import slide2 from '../images/ribeye.jpg';
import slide3 from '../images/drinks.jpg';
import slide4 from '../images/lobby.jpg';
import slide5 from '../images/GiftCard.jpg';
import slide6 from '../images/patio.jpg';

const items = [
  {
    src: `${ slide1 }`,
    altText: 'Our Full Service Bar',
    caption: 'Join us for Margaritas, Piña Coladas and Beer on Tap',
    header: 'Our Full Service Bar',
    key: '1'
  },
  {
    src: `${ slide2 }`,
    altText: 'Mexican Ribeye Steak with Shrimp',
    caption: '16oz Angus Ribeye grilled over charcoal to perfection',
    header: 'Mexican Ribeye Steak with Shrimp',
    key: '2'
  },
  {
    src: `${ slide3 }`,
    altText: 'Drinks',
    caption: 'Margaritas, Sprites and Tequila Sunrise',
    header: 'Come Try Our Wide Variety of Drinks',
    key: '3'
  },
  {
    src: `${ slide4 }`,
    altText: 'Lobby',
    caption: 'Come and check out our newly remodeled restaurant',
    header: 'Welcome to The Green Box!',
    key: '4'
  },
  {
    src: `${ slide5 }`,
    altText: 'Gift Card',
    caption: 'Buy a gift card for your loved ones this holiday',
    header: 'Gift Cards Available For The Holidays',
    key: '5'
  },
  {
    src: `${ slide6 }`,
    altText: 'Patio Seating',
    caption: 'Join us for a meal in our new outdoor patio seating',
    header: 'Outdoor Seating Available',
    key: '6'
  },
];

const ImgCarousel = () => {
    return (
    <div>
    <Card className='cardHome'>
        <CardHeader className="square">
            <CardTitle tag="h2">Gallery</CardTitle>
        </CardHeader>
    </Card>
            <UncontrolledCarousel items={items} />
    </div>
    )};

export default ImgCarousel;
