import React, { Component} from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Jumbotron } from 'reactstrap';
import Menu from "./menu";
import axios from "axios";
import classnames from 'classnames';
import { base } from "./home"
import jumbo from '../images/Cactus_in_the_Desert.png';

class MenuNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
        activeTab : "1",
        headers:[],
        };
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        this.loadMenu();
    }

    async loadMenu() {
        var url = (this.props.menu_type === "dinner") ? base + "dinnermenujsonoutput/" : base + "drinkmenujsonoutput/";
        const prom = await axios.get(url);
        const menu = prom.data;
        this.setState({ headers:menu });
    }

  toggle(tab) {
    if(this.state.activeTab !== tab) this.setState({activeTab:tab});
    }

  icons = () => {
    var i = 2;
    var iconlist = [];
    iconlist.push(["1", "All"])
    for (var item of this.state.headers){
        var x = i.toString();
        iconlist.push([ x , item.title ])
        i = parseInt(x, 10);
        i++;
        }
    return iconlist
  }

  navbutton = () => {
    var buttons = [];
    var icon;
    this.icons().forEach((entry) => {
        icon = (
                <NavLink href="#" onClick={() => { this.toggle( entry[0] ); }} className={classnames({ active: this.state.activeTab === entry[0]})}>{entry[1]}</NavLink>
            );
        buttons.push(icon);
        });
    return buttons;
  }

  tabbutton = () => {
    var buttons = [];
    var icon;
    this.icons().forEach((entry) => {
        icon = (
        <TabPane tabId={entry[0]}>
            <Menu menu_type = {this.props.menu_type} type={entry[1]}/>
        </TabPane>
            );
        buttons.push(icon);
        });
    return buttons;
  }

render() {
        const jumboStyle = {
            backgroundImage: 'url(' + jumbo + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'center 25%',
            backgroundColor: 'rgb(245 241 196)',
        }
        const darkBack = {
            color: "#ffffff",
            backgroundColor: 'rgba(47,47,47,.7)',
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: '20px',
            borderRadius: '2%',
        }
  return (
    <div className="menuContent">
      <Jumbotron style={jumboStyle} className="menuHeader">
        <h1 style={darkBack}>{ (this.props.menu_type === "dinner") ? "Dinner Menu" : "Drink Menu" }</h1>
      </Jumbotron>
      <Nav pills>
        { this.navbutton().map ((value, index) => {return <NavItem key={index}>{value}</NavItem>})}
      </Nav>

      <TabContent activeTab={this.state.activeTab}>
          {this.tabbutton()}
      </TabContent>
    </div>
  );
}
}

export default MenuNav;