import React, { useState } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import logo from '../images/elCaporalLogoMini.png'
const Mynav = (props) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <div>
      <Navbar fixed="top" expand="md" light>
        <NavbarBrand href="/"><img width="150" height="45" src={logo} alt="El Caporal"></img></NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} />
        <Collapse isOpen={!collapsed} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink href="/dinnermenu">Dinner Menu</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/drinkmenu">Drink Menu</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default Mynav;