import React, { Component } from "react";
import axios from "axios";
import { Card, CardHeader, CardBody, CardTitle, CardText } from 'reactstrap';
import { base } from "./home"

export default class Menu extends Component {
    state = {
    menu: [],
    };


    componentDidMount() {
        this.loadMenu();
    }

    filterMenu() {
        const filter = [];
        this.state.menu.map(item => item.title === this.props.type ? filter.push(item) : null );
        const menu = filter;
        this.setState({ menu });
    }

    async loadMenu() {
        var url = (this.props.menu_type === "dinner") ? base + "dinnermenujsonoutput/" : base + "drinkmenujsonoutput/";
        const prom = await axios.get(url);
        const menu = prom.data;
        this.setState({ menu });
        if (this.props.type !== "All") {
        this.filterMenu();
        }
    }



  render() {
    return(
      <div>
            { this.state.menu.map((item, index) => (
                <div key={index}>
                <Card className="cardHome">
                <CardBody>
                    <Card className="specialCard">
                        <CardHeader>
                            <CardText tag="h3">{item.title}</CardText>
                            <CardText tag="h6">{item.description}</CardText>
                        </CardHeader>
                        { item.items.map((value, index) => (
                            <div key={index}>
                                    <CardBody>
                                        <CardTitle tag="h4">{value.name}</CardTitle>
                                        <CardText>{value.description}</CardText>
                                        <CardText tag="h6">{value.price}</CardText>
                                    </CardBody>
                            </div>
                        ))}
                    </Card>
                    </CardBody>
                    </Card>
                </div>
            ))}
      </div>
    )
  }
}