import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import MenuNav from "./component/nav";
import Mynav from "./component/navbar";
import Home from "./component/home";
import Footer from "./component/footer";

class App extends Component {
  render() {
    return (
    <div>
    <Mynav />
      <Router>
        <Route path="/" exact component={Home} />
        <Route path="/dinnermenu"><MenuNav menu_type="dinner" /></Route>
        <Route path="/drinkmenu"><MenuNav menu_type="drink" /></Route>
      </Router>
    <Footer />
    </div>
    );
  }
}

export default App;