import React, { Component } from "react";
import { Jumbotron, Table, Card, CardHeader, CardBody, CardTitle, CardText, Container, Row, Col } from "reactstrap";
import axios from "axios";
import ImgCarousel from "./imageCarousel";
import night from '../images/ribeye.jpg';
import day from '../images/drinks.jpg';
import jumbo from '../images/lobby.jpg';

export const base = "/";

export default class Home extends Component {

    render() {

        const jumboStyle = {
            backgroundImage: 'url(' + jumbo + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'center 25%',
            backgroundColor: 'rgb(245 241 196)',
        }
        const darkBack = {
            color: "#ffffff",
            backgroundColor: 'rgba(47,47,47,.7)',
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: '20px',
            borderRadius: '3%',
        }

        return(
            <div>
                <Jumbotron style={jumboStyle} className="square homePageHeaderSpot">
                    <div style={darkBack}>
                        <h1>AUTHENTIC HOMEMADE MEXICAN FOOD IN THE UPPER ROGUE VALLEY </h1>
                        <hr />
                        <p>FAMILY OWNED RESTAURANT - MOM'S RECIPES FOR TAMALES AND CHILE RELLENOS - FULL SERVICE BAR - NICE QUIET ATMOSPHERE </p>
                        <h5>WE ALSO HAVE CARRY OUT</h5>
                        <h5>14420 HWY 62 EAGLE POINT, OR 97524</h5>
                        <h5>(541) 826-4360</h5>
                    </div>
                </Jumbotron>
                <MenuSpots />
                <Announcements />
                <Container fluid>
                    <Row>
                        <Col className="twoSpot" sm="6">
                           <TimeCard />
                        </Col>
                        <Col className="twoSpot" sm="6">
                            <DinnerSpecial />
                        </Col>
                    </Row>
                </Container>
                <ImgCarousel />
            </div>
        )
    }
}

class TimeCard extends Component {
            constructor(props) {
            super(props);
            this.state = {
                hours:[],
            };
        }

        componentDidMount() {
            this.loadData();
        }

        async loadData() {
        var prom = await axios.get( base + "hoursjsonoutput/")
        var data = prom.data;
        this.setState({ hours:data });
    }
    render() {
        return (
        <div>
            <Card className='cardHome'>
                <CardHeader className="square">
                    <CardTitle tag="h2">Hours</CardTitle>
                 </CardHeader>
            <CardBody>
            <Card className='specialCard'>
            <CardBody>
            <Table borderless>
                <tbody>
                    { this.state.hours.map((value, index) => {return (
                        <tr key={index}>
                            <td>{value.day}</td>
                            <td>{value.time}</td>
                        </tr>
                    )})}
                </tbody>
            </Table>
            </CardBody>
            </Card>
            </CardBody>
            </Card>
        </div>
        )
    }
}

class Announcements extends Component {
            constructor(props) {
            super(props);
            this.state = {
                announce:[],
            };
        }

        componentDidMount() {
            this.loadData();
        }

        async loadData() {
        var prom = await axios.get( base + "announcementsjsonoutput/")
        var data = prom.data;
        this.setState({ announce:data });
    }
    render() {
        return (
        <div>
            { this.state.announce.map((value, index) => {return (
                <Card key={index} className='cardHome'>
                    <CardHeader className="square">
                        <CardTitle tag="h2">{value.title}</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <CardText>{value.description}</CardText>
                    </CardBody>
                </Card>
            )})}
        </div>
        )
    }
}

class DinnerSpecial extends Component {
            constructor(props) {
            super(props);
            this.state = {
                menu:[],
            };
        }

        componentDidMount() {
            this.loadData();
        }

        filterMenu(keyword) {
        const filter = [];
        this.state.menu.map(item => item.title === keyword ? filter.push(item) : null );
        const menu = filter;
        this.setState({ menu });
        }

        async loadData() {
        var prom = await axios.get(base + "dinnermenujsonoutput/")
        var data = prom.data;
        this.setState({ menu:data });
        this.filterMenu("Dinner Special");

    }
    render() {
        return (
        <div>
            <Card className="cardHome">
               <CardHeader className="square">
                    <CardTitle tag="h2">This Weeks Dinner Special</CardTitle>
                </CardHeader>
            <CardBody>
            { this.state.menu.map((item, index) => {return (
                <Card className='specialCard'>
                        { item.items.map((value, index) => (
                            <div key={index}>
                                    <CardBody>
                                        <CardTitle tag="h4">{value.name}</CardTitle>
                                        <CardText>{value.description}</CardText>
                                        <CardText tag="h6">{value.price}</CardText>
                                    </CardBody>
                            </div>
                        ))}
                </Card>
            )})}
            </CardBody>
            </Card>
        </div>
        )
    }
}

class MenuSpots extends Component {

    render() {

        const nightStyle = {
            backgroundImage: 'url(' + night + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'center 45%',
            backgroundColor: 'rgb(149 153 173)',
        }

        const dayStyle = {
            backgroundImage: 'url(' + day + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'center 45%',
            backgroundColor: 'rgb(245 241 196)',
        }
        const menuBack = {
            width: '40%',
            padding: '10px',
            color: "#ffffff",
            backgroundColor: 'rgba(47,47,47,.7)',
            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: '3%',
        }
        return(
            <div>
                <Card className='cardHome'>
                    <CardHeader className="square">
                        <CardTitle tag="h2">Our Menus</CardTitle>
                    </CardHeader>
                </Card>
                <Container fluid className="menuSpotsContainer">
                    <Row className="menuSpotsRow">
                    <Col className="menuSpotLeft" sm="6">
                        <a href="/dinnermenu">
                            <button style={nightStyle}>
                                <div style={menuBack}>
                                    Dinner Menu
                                </div>
                            </button>
                        </a>
                    </Col>
                    <Col sm="6" className="menuSpotRight">
                        <a href="/drinkmenu">
                            <button style={dayStyle}>
                                <div style={menuBack}>
                                    Drink Menu
                                </div>
                            </button>
                        </a>
                    </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}